import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  width: 500px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;

  strong {
    margin-bottom: 16px;
  }
`;

export const Left = styled.aside`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Right = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  strong {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .date {
    width: 100%;
    margin: 25px 0 16px;

    input {
      width: 100%;
    }
  }

  .file_name {
    input {
      width: 100%;
    }
  }

  > button {
    padding: 8px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;
    padding-left: 20px;
    padding-right: 20px;

    background: ${({ theme }) => theme.colors.success};
    margin-right: 16px;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }
  }
  .body {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    align-items: baseline;
    // flex-direction: row;
    // justify-content: space-between;
  }

  .dpt_labour {
    margin-bottom: 16px;

    // flex-direction: row-reverse;
    // align-items: center;
    // justify-content: flex-end;

    > input {
      color: ${({ theme }) => theme.colors.blue};
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    padding-top: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: transparent;
  }
`;

export const File = styled.div`
  width: 100%;

  div {
    width: 100%;
  }

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

export const Delete = styled.div`
  height: 40px;
  padding-top: 20px;
  margin-right: 10px;

  display: flex;
  justify-content: center;

  button {
    color: #e53935;
  }
`;
