import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';

import {
  format,
  parseISO,
  isToday,
  startOfDay,
  endOfDay,
  subDays,
} from 'date-fns';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea/lib';

import {
  FaClock,
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaMinus,
  FaDownload,
  FaLocationArrow,
  FaPaperclip,
  FaBan,
  FaRegCircle,
  FaThumbsDown,
  FaThumbsUp,
  FaClipboard,
} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Report from './Report';

import ConfirmWindow from '~/components/ConfirmWindow';
import Pagination from '~/components/Pagination';
import {
  Checkbox,
  FileInput,
  Select,
  SelectWithFilterActiveCheckbox,
  TextArea,
} from '~/components/Form';
import { TableContainer, TableLoading } from '~/components/Table';
import Loading from '~/components/Loading';
import PermissionComponent from '~/components/PermissionComponent';
import FeedbacksModal from '~/components/FeedbacksModal';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import UpdateDocumentModal from './UpdateDocumentModal';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  Subtitles,
  DocumentTd,
  Feedback,
  SendFeedback,
  UploadFile,
  FileName,
  File,
  FeedbackItem,
} from './styles';
import DocumentsModal from './DocumentsModal';

const List = () => {
  const { user, company, companyUsers, companyUser } = useAuth();

  const { state } = useLocation();
  const [model_id, setModelId] = useState(state?.model_id || null);

  const [loading, setLoading] = useState(false);
  const [processesLoading, setProcessesLoading] = useState(true);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);

  const [showTable, setShowTable] = useState(true);

  const [filterUser, setFilterUser] = useState(null);
  const [filterClient, setFilterClient] = useState(null);

  const filterRef = useRef(null);
  const feedbackRef = useRef(null);
  const feedbacksForModal = useRef([]);

  const [feedbacksModalIsOpen, setFeedbacksModalIsOpen] = useState(false);

  const [indexProcessView, setIndexProcessView] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProcesses, setTotalProcesses] = useState(0);

  const [processType, setProcessType] = useState(null);
  const [processes, setProcesses] = useState([]);
  const [process, setProcess] = useState(null);

  const [updateDocumentModalIsOpen, setUpdateDocumentModalIsOpen] = useState(
    false
  );
  const [documentForModal, setDocumentForModal] = useState({});

  const [uploadFile, setUploadFile] = useState([]);

  const viewOptions = useMemo(() => {
    const options = [
      { label: 'Todos', value: '' },
      { label: 'Não visualizado', value: 0 },
      { label: 'Visualizado', value: 1 },
    ];

    return options;
  }, []);

  const [selectedView, setSelectedView] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  useEffect(() => {
    if (user) {
      const userStorage = localStorage.getItem(
        '@Diretiva:expiration-control:filter:user'
      );
      const clientStorage = localStorage.getItem(
        '@Diretiva:expiration-control:filter:client'
      );
      const viewStorage = localStorage.getItem(
        '@Diretiva:expiration-control:filter:view'
      );

      if (userStorage) {
        setSelectedUser(JSON.parse(userStorage));
        setFilterUser(JSON.parse(userStorage));
      } else {
        setSelectedUser({
          value: user.id,
          label: user.name,
        });
        setFilterUser({
          value: user.id,
          label: user.name,
        });
      }

      if (clientStorage) {
        setSelectedClient(JSON.parse(clientStorage));
        setFilterClient(JSON.parse(clientStorage));
      } else {
        setSelectedClient({
          value: '',
          label: 'Todos',
        });
        setFilterClient({
          value: '',
          label: 'Todos',
        });
      }

      if (viewStorage) {
        setSelectedView(JSON.parse(viewStorage));
      } else {
        setSelectedView({
          value: '',
          label: 'Todos',
        });
      }
    }
  }, [user]);

  const [usersOptions, setUsersOptions] = useState([]);
  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: '',
        label: 'Todos',
        active: true,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  const [processModelsOptions, setProcessModelsOptions] = useState([]);
  useEffect(() => {
    if (!company) return;

    async function loadModelsOptions() {
      try {
        const response = await api.get(`/process-models/simple`, {
          params: {
            company_id: company.id,
            model_type: 2,
            selectOnly: true,
          },
        });

        if (response.data.length > 0) {
          const options = response.data.map(item => ({
            value: item.id,
            label: item.menu_title || item.title,
          }));

          options.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setProcessModelsOptions(options);
        } else {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/process-models');
        }
      } catch {
        toast.error('Falha ao buscar modelos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push('/process-models');
      }
    }

    loadModelsOptions();
  }, [company]);

  const handleFilterUsersOptions = useCallback(
    value => {
      if (user && companyUsers) {
        if (value === true) {
          setUsersOptions(oldUsersOptions =>
            oldUsersOptions.filter(userItem => userItem.active !== false)
          );
        } else {
          const options = companyUsers
            .filter(userItem => userItem.user_id !== user.id)
            .filter(userItem => userItem.user_id !== -1)
            .map(userItem => {
              return {
                value: userItem.user_id,
                label: userItem.short_name,
                active: userItem.active,
              };
            });

          options.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          options.unshift({
            value: user.id,
            label: user.short_name,
            active: true,
          });

          options.push({
            value: '',
            label: 'Todos',
            active: true,
          });

          setUsersOptions(options);
        }
      }
    },
    [companyUsers, user]
  );

  const [clients, setClients] = useState(null);
  const [clientsOptions, setClientsOptions] = useState([]);

  useEffect(() => {
    async function loadClients() {
      if (user && company) {
        try {
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: 1,
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            const options = response.data.map(item => ({
              value: item.id,
              label: item.name,
              active: item.active,
            }));

            options.unshift({
              value: '',
              label: 'Todos',
              active: true,
            });

            setClients(options);
            setClientsOptions(options.filter(item => item.active));
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadClients();
  }, [company, user]);

  const handleFilterClientsOptions = useCallback(
    value => {
      if (clients) {
        if (value === true) {
          setClientsOptions(clients.filter(clientItem => clientItem.active));
        } else {
          setClientsOptions(clients);
        }
      }
    },
    [clients]
  );

  const [reportLoading, setReportLoading] = useState(false);
  const [report, setReport] = useState([]);
  const reportRef = useRef();
  const printReportData = async () => {
    try {
      setReportLoading(true);

      const response = await api.get(`/processes`, {
        params: {
          company_id: company.id,
          model_id,
          user_id: selectedUser ? selectedUser.value : user.id,
          client_id: selectedClient ? selectedClient.value : '',
          view: selectedView ? selectedView.value : '',
          page: -1,
          type: 'expiration-control',
        },
      });

      if (response.data.docs.length > 0) {
        const array = [];
        response.data.docs.forEach(item =>
          item.documents.forEach(documentItem => {
            const expiration = parseISO(documentItem.expiration_date);
            const expirationEnd = endOfDay(
              parseISO(documentItem.expiration_date)
            );
            const notificateStart = startOfDay(
              subDays(expiration, documentItem.days_to_notificate)
            );
            const todayStart = startOfDay(new Date());
            const todayEnd = endOfDay(new Date());

            let situation = 0;

            if (documentItem.situation === 3) {
              situation = 3;
            } else if (expiration < todayStart) {
              situation = 2;
            } else if (
              todayStart >= notificateStart &&
              todayEnd <= expirationEnd
            ) {
              situation = 1;
            } else if (notificateStart > todayEnd) {
              situation = 0;
            }

            if (situation === 2) {
              const data = {
                name: item.client.nickname || item.client.name,
                stage: documentItem.document,
                expiration_date: documentItem.expiration_date,
              };
              array.push(data);
            }
          })
        );

        const nameCounts = array.reduce((acc, item) => {
          acc[item.name] = (acc[item.name] || 0) + 1;
          return acc;
        }, {});

        const filteredArray = array.filter(item => nameCounts[item.name] >= 4);

        filteredArray.sort(
          (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
        );

        setReportLoading(false);
        setReport(filteredArray);
      } else {
        toast.warn('Nenhum cliente para gerar relatório encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      toast.error('Falha ao gerar relatório.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setReportLoading(false);
    }
    setReportLoading(false);
  };

  useEffect(() => {
    if (report.length === 0) return;
    setReportLoading(false);

    const printContents = reportRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }, [report]);

  const loadProcesses = useCallback(async () => {
    if (user && company && selectedUser) {
      try {
        setProcessesLoading(true);

        const response = await api.get(`/processes`, {
          params: {
            company_id: company.id,
            model_id,
            user_id: selectedUser ? selectedUser.value : user.id,
            client_id: selectedClient ? selectedClient.value : '',
            view: selectedView ? selectedView.value : '',
            page: currentPage,
            type: 'expiration-control',
          },
        });

        const { docs, pages, total } = response.data;

        if (docs.length > 0) {
          const data = docs.map(item => {
            const documents = item.documents.map(documentItem => {
              if (documentItem.expiration_date) {
                const expiration = parseISO(documentItem.expiration_date);
                const expirationEnd = endOfDay(
                  parseISO(documentItem.expiration_date)
                );
                const notificateStart = startOfDay(
                  subDays(expiration, documentItem.days_to_notificate)
                );
                const todayStart = startOfDay(new Date());
                const todayEnd = endOfDay(new Date());

                let situation = 0;

                if (documentItem.situation === 3) {
                  situation = 3;
                } else if (expiration < todayStart) {
                  situation = 2;
                } else if (
                  todayStart >= notificateStart &&
                  todayEnd <= expirationEnd
                ) {
                  situation = 1;
                } else if (notificateStart > todayEnd) {
                  situation = 0;
                }

                return {
                  ...documentItem,
                  formatted_expiration: format(expiration, 'dd/MM/yyyy'),
                  situation,
                };
              }

              return {
                ...documentItem,
                formatted_expiration: ' - ',
                situation: documentItem.situation === 3 ? 3 : 4,
              };
            });

            const feedbacks = item.feedbacks.map(feedbackItem => ({
              ...feedbackItem,
              feedback_date: format(
                parseISO(feedbackItem.created_at),
                "dd/MM/yyyy 'às' HH:mm"
              ),
            }));

            let situation = 2;
            if (item.situation !== 2) {
              situation = item.process_for_situation ? 0 : 1;
            }

            return {
              ...item,
              situation,
              start_date: format(parseISO(item.start_date), 'dd/MM/yyyy'),
              documents,
              feedbacks,
            };
          });

          setProcesses(data);
          setProcess(data[0]);
          setIndexProcessView(0);
        } else {
          toast.warn('Nenhum processo foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setProcesses([]);
          setProcess(null);
          setIndexProcessView(-1);
        }

        setTotalPages(pages);
        setTotalProcesses(total);
        setProcessesLoading(false);
      } catch {
        toast.error('Falha ao buscar processos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setProcessesLoading(false);
      }
    }
  }, [
    user,
    company,
    model_id,
    selectedUser,
    selectedClient,
    selectedView,
    currentPage,
  ]);

  useEffect(() => {
    async function loadModel() {
      try {
        const response = await api.get(
          `/process-models/new-process/${model_id}`
        );

        if (!response.data) {
          toast.error('Falha ao buscar modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/expiration-control-models');
        }

        setProcessType(response.data);
      } catch {
        toast.error('Falha ao buscar modelo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push('/expiration-control-models');
      }
    }

    if (!model_id) {
      toast.warn('Nenhum modelo selecionado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/expiration-control-models');
    } else {
      loadModel();
      loadProcesses();
    }
  }, [model_id, loadProcesses]);

  const handleSubmitFilter = useCallback(
    data => {
      setSelectedUser(usersOptions.find(option => option.value === data.user));
      localStorage.setItem(
        '@Diretiva:expiration-control:filter:user',
        JSON.stringify(usersOptions.find(option => option.value === data.user))
      );

      setSelectedClient(
        clientsOptions.find(option => option.value === data.client)
      );
      localStorage.setItem(
        '@Diretiva:expiration-control:filter:client',
        JSON.stringify(
          clientsOptions.find(option => option.value === data.client)
        )
      );

      setSelectedView(viewOptions.find(option => option.value === data.view));
      localStorage.setItem(
        '@Diretiva:expiration-control:filter:view',
        JSON.stringify(viewOptions.find(option => option.value === data.view))
      );
    },
    [usersOptions, clientsOptions, viewOptions]
  );

  const resetFilter = useCallback(() => {
    filterRef.current.setFieldValue(
      'user',
      usersOptions.find(option => option.value === user.id)
    );

    setSelectedUser(usersOptions.find(option => option.value === user.id));
    setFilterUser(usersOptions.find(option => option.value === user.id));
    localStorage.removeItem('@Diretiva:expiration-control:filter:user');

    filterRef.current.setFieldValue('client', { label: 'Todos', value: '' });

    setSelectedClient({ label: 'Todos', value: '' });
    setFilterClient({ label: 'Todos', value: '' });
    localStorage.removeItem('@Diretiva:expiration-control:filter:client');

    setSelectedView({ label: 'Todos', value: '' });
    filterRef.current.setFieldValue('view', { label: 'Todos', value: '' });
    localStorage.removeItem('@Diretiva:expiration-control:filter:view');
  }, [user, usersOptions]);

  const alterView = useCallback(() => {
    if (processes.length > 0 || !showTable) {
      setShowTable(!showTable);
    }
  }, [processes, showTable]);

  const handleDeleteProcess = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`/processes/${process.id}`);

      setProcesses(oldProcesses =>
        oldProcesses.filter(item => item.id !== process.id)
      );

      alterView();

      loadProcesses();

      setLoading(false);
    } catch {
      toast.error('Falha ao deletar processo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }, [process, alterView, loadProcesses]);

  const confirmDeleteProcess = useCallback(() => {
    if (user && companyUser) {
      if (process.user_id !== user?.id && companyUser.level < 9) {
        toast.warn('Somente o criador do processo pode deletá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow onClick={handleDeleteProcess} onClose={onClose} />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [handleDeleteProcess, process, user, companyUser]);

  const handlePrevItem = useCallback(() => {
    if (indexProcessView === 0) {
      setProcess(processes[processes.length - 1]);
      setIndexProcessView(processes.length - 1);
    } else {
      setProcess(processes[indexProcessView - 1]);
      setIndexProcessView(indexProcessView - 1);
    }
  }, [indexProcessView, processes]);

  const handleNextItem = useCallback(() => {
    if (indexProcessView === processes.length - 1) {
      setProcess(processes[0]);
      setIndexProcessView(0);
    } else {
      setProcess(processes[indexProcessView + 1]);
      setIndexProcessView(indexProcessView + 1);
    }
  }, [indexProcessView, processes]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  const editProcess = useCallback(() => {
    if (user && companyUser) {
      if (process.user_id !== user?.id && companyUser.level < 9) {
        toast.warn('Somente o criador do processo pode editá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        history.push({
          pathname: '/expiration-control/edit',
          state: { id: process.id, model_id },
        });
      }
    }
  }, [user, companyUser, process, model_id]);

  const handleFeedback = useCallback(
    async data => {
      if (company && user) {
        try {
          setFeedbackLoading(true);

          const schema = Yup.object().shape({
            feedback_message: Yup.string().when('file', {
              is: value => value !== undefined,
              then: Yup.string(),
              otherwise: Yup.string().required('A mensagem é obrigatória'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const feedbackData = {
            company_id: company.id,
            process_id: process.id,
            user_id: user.id,
            content:
              data.file && data.feedback_message === ''
                ? data.file.name
                : data.feedback_message,
            type: data.file ? 1 : 0,
            file_name: data.file ? data.file.name : null,
          };

          if (data.file) {
            const formData = new FormData();

            formData.append('file', data.file);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Process_Feedback',
              },
            });

            const { blobName } = fileResponse.data;

            feedbackData.link = blobName;
          }

          await api.post('processes/feedback', feedbackData);

          const response = await api.get(`processes/feedback/${process.id}`);

          const feedbacks = response.data.map(feedback => ({
            ...feedback,
            feedback_date: format(
              parseISO(feedback.created_at),
              "dd/MM/yyyy 'às' HH:mm"
            ),
          }));

          setProcess(oldProcess => ({
            ...oldProcess,
            feedbacks,
          }));

          setProcesses(oldProcesses =>
            oldProcesses.map(processItem => ({
              ...processItem,
              feedbacks:
                processItem.id === process.id
                  ? feedbacks
                  : processItem.feedbacks,
            }))
          );

          feedbackRef.current.clearField('feedback_message');

          feedbackRef.current.setErrors({});

          setUploadFile([]);
          setFeedbackLoading(false);

          toast.success('Feedback salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            feedbackRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar feedback.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setUploadFile([]);
          setFeedbackLoading(false);
        }
      }
    },
    [company, user, process]
  );

  const handleDeleteFeedback = useCallback(
    async (id, processId, userId, date) => {
      if (user) {
        if (user.id === userId) {
          if (isToday(parseISO(date))) {
            setDeleteFeedbackLoading(true);
            try {
              await api.delete(`processes/feedback/${id}`);

              setProcesses(
                processes.map(item =>
                  item.id === processId
                    ? {
                        ...item,
                        feedbacks: item?.feedbacks?.filter(
                          feedback => feedback.id !== id
                        ),
                      }
                    : item
                )
              );

              setProcess(oldProcess => ({
                ...oldProcess,
                feedbacks: oldProcess?.feedbacks?.filter(
                  feedback => feedback.id !== id
                ),
              }));

              toast.success('Mensagem deletada com sucesso.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } catch {
              toast.error('Falha ao deletar mensagem.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } finally {
              setDeleteFeedbackLoading(false);
            }
          } else {
            toast.warn('Não é possivel deletar mensagens antigas.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.warn('Somente o criador da mensagem pode deletá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [user, processes]
  );

  const confirmRemoveFeedback = useCallback(
    (id, processId, userId, date) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteFeedback(id, processId, userId, date)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteFeedback]
  );

  useEffect(() => {
    if (!showTable && !processesLoading) {
      const element = document.getElementById('feedback_message');

      if (element) {
        element.addEventListener('keydown', event => {
          if (event.ctrlKey && event.key === 'Enter') {
            feedbackRef.current.submitForm();
          }
        });
      }
    }
  }, [showTable, processesLoading]);

  useEffect(() => {
    if (uploadFile.length !== 0) {
      feedbackRef.current.submitForm();
    }
  }, [uploadFile]);

  const handleUpdateDocumentModal = useCallback(
    (user_id, document, client) => {
      if (
        user_id === companyUser.user_id ||
        document.responsible_id === companyUser.user_id ||
        companyUser.level === 9
      ) {
        setDocumentForModal({
          ...document,
          client,
        });
        setUpdateDocumentModalIsOpen(true);
      } else {
        toast.warn('Só o criador pode alterar o vencimento.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [companyUser]
  );

  const handleReleaseAllDocuments = useCallback(async () => {
    try {
      setLoading(true);

      await api.put(`/processes/release-documents/${process.id}`, {
        user_id: user.id,
        company_id: company.id,
      });

      const response = await api.get(`processes/feedback/${process.id}`);

      const feedbacks = response.data.map(feedback => ({
        ...feedback,
        feedback_date: format(
          parseISO(feedback.created_at),
          "dd/MM/yyyy 'às' HH:mm"
        ),
      }));

      setProcess(oldProcess => ({
        ...oldProcess,
        feedbacks,
        documents: oldProcess.documents.map(doc => ({
          ...doc,
          situation: 3,
        })),
      }));

      setProcesses(oldProcesses =>
        oldProcesses.map(processItem => ({
          ...processItem,
          feedbacks:
            processItem.id === process.id ? feedbacks : processItem.feedbacks,
          documents:
            processItem.id === process.id
              ? processItem.documents.map(doc => ({
                  ...doc,
                  situation: 3,
                }))
              : processItem.documents,
        }))
      );

      setLoading(false);
    } catch {
      setLoading(false);
      toast.error('Ocorreu um erro ao desobrigar os documentos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [process, user, company]);

  const confirmReleaseAllDocuments = useCallback(() => {
    if (process.user_id !== user?.id && companyUser.level < 9) {
      toast.warn('Somente o criador do processo pode editá-lo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={handleReleaseAllDocuments}
              onClose={onClose}
              message="Deseja desobrigar todos os documentos deste controle de vencimento?"
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  }, [process, user, companyUser, handleReleaseAllDocuments]);

  const handleViewDocumentFeedbacks = useCallback(async documentId => {
    try {
      setLoading(true);

      const response = await api.get(`/processes/doc-feedbacks/${documentId}`);

      if (response.data.length > 0) {
        const formattedFeedbacks = response.data.map(feedback => ({
          ...feedback,
          feedback_date_formatted: format(
            parseISO(feedback.feedback_date),
            "dd/MM/yyyy 'às' HH:mm"
          ),
        }));

        feedbacksForModal.current = formattedFeedbacks;

        setFeedbacksModalIsOpen(true);
      } else {
        toast.warn('Nenhum feedback encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      setLoading(false);
    } catch {
      toast.error('Falha ao buscar os feedbacks.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    async function checkViewProcess() {
      if (process && user && user.id === process.user_id && !showTable) {
        if (process.view === false) {
          await api.put(`/processes/check-view/${process.id}`);

          setProcesses(oldProcesses =>
            oldProcesses.map(item => {
              if (item.id === process.id) {
                return {
                  ...item,
                  view: true,
                };
              }

              return item;
            })
          );
        }
      }
    }
    checkViewProcess();
  }, [process, user, showTable]);

  const handleCheckAllUsers = useCallback(
    e => {
      const { checked } = e.target;

      if (checked) {
        setSelectedUser({ value: '', label: 'Todos' });
        setFilterUser({ value: '', label: 'Todos' });
        localStorage.setItem(
          '@Diretiva:expiration-control:filter:user',
          JSON.stringify({ value: '', label: 'Todos' })
        );
        filterRef.current.setFieldValue('user', { value: '', label: 'Todos' });
      } else {
        setSelectedUser(usersOptions[0]);
        setFilterUser(usersOptions[0]);
        localStorage.setItem(
          '@Diretiva:expiration-control:filter:user',
          JSON.stringify(usersOptions[0])
        );
        filterRef.current.setFieldValue('user', usersOptions[0]);
      }
    },
    [usersOptions]
  );

  const [documentsModalIsOpen, setDocumentsModalIsOpen] = useState(false);
  const filterForDocumentsModal = useRef({});

  const handleOpenDocumentsModal = useCallback(
    situation => {
      const client_id = filterRef.current.getFieldValue('client');
      const user_id = filterRef.current.getFieldValue('user');

      filterForDocumentsModal.current = {
        situation,
        client_id,
        user_id,
        company_id: company.id,
        model_id,
      };

      setDocumentsModalIsOpen(true);
    },
    [company, model_id]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            {processType && (
              <>
                <FontAwesomeIcon
                  icon={processType.icon.name}
                  size="lg"
                  color="#44546a"
                />
                <h1>{processType.menu_title || processType.title}</h1>
              </>
            )}
          </div>
          <aside>
            <Link to="/expiration-control-models">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <Link
            to={{ pathname: '/expiration-control/new', state: { model_id } }}
          >
            <FaPlus />
            <span>Novo</span>
          </Link>
          {showTable ? (
            <>
              <button type="button" onClick={resetFilter}>
                <FaEraser />
                <span>Limpar filtros</span>
              </button>
              <button type="button" onClick={printReportData}>
                <FaClipboard />
                <span>Relatório</span>
              </button>
            </>
          ) : (
            <>
              <PermissionComponent level={9}>
                <button type="button" onClick={editProcess}>
                  <FaEdit />
                  <span>Editar</span>
                </button>
              </PermissionComponent>

              <button type="button" onClick={confirmDeleteProcess}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>

                <span>
                  {indexProcessView + 1} de {processes.length}
                </span>

                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>

        {showTable &&
          selectedUser &&
          clients &&
          processModelsOptions.length > 0 && (
            <Filter ref={filterRef} onSubmit={handleSubmitFilter}>
              <Select
                label="Modelo"
                name="processModel"
                className="user"
                options={processModelsOptions}
                defaultValue={
                  processModelsOptions.find(item => item.value === model_id) ||
                  null
                }
                onChange={e => setModelId(e.value)}
              />
              <SelectWithFilterActiveCheckbox
                label="Cliente"
                name="client"
                className="user"
                options={clientsOptions}
                value={filterClient}
                handleFilter={handleFilterClientsOptions}
                onChange={e => setFilterClient(e)}
              />
              <Select
                name="view"
                label="Visualização"
                className="view"
                options={viewOptions}
                defaultValue={selectedView}
              />
              <SelectWithFilterActiveCheckbox
                label="Usuário"
                name="user"
                className="user"
                options={usersOptions}
                value={filterUser}
                handleFilter={handleFilterUsersOptions}
                onChange={e => setFilterUser(e)}
              />
              <Checkbox
                name="allUsers"
                label="Todos"
                className="all-users"
                checked={filterUser && filterUser.value === ''}
                onChange={handleCheckAllUsers}
              />
              <div className="buttons">
                <button
                  type="button"
                  onClick={() => handleOpenDocumentsModal(0)}
                >
                  <FaRegCircle color="#9dd3fe" />
                  <span>Não atribuídos</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleOpenDocumentsModal(1)}
                >
                  <FaClock color="#f4c306" />
                  <span>A vencer</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleOpenDocumentsModal(2)}
                >
                  <FaClock color="#E53935" />
                  <span>Vencidos</span>
                </button>
              </div>

              <button type="submit">
                <FaSearch />
              </button>
            </Filter>
          )}

        {processesLoading || !processType || !processes ? (
          <TableLoading />
        ) : (
          <>
            {showTable ? (
              <>
                <Content className="content">
                  <ReactTooltip
                    id="document"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                  />
                  <ReactTooltip
                    id="client"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                  />
                  <ReactTooltip
                    id="mark"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                    multiline
                  />
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="thumb" />
                        <th className="client">Empresa</th>
                        {processType.documents &&
                          processType.documents.map(document => (
                            <th className="document" key={document.id}>
                              <span
                                data-tip={document.document}
                                data-for="document"
                                color="#337ab7"
                              >
                                {document.abbreviation}
                              </span>
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {processes &&
                        processes.map((item, indexItem) => (
                          <tr key={item.id}>
                            <th className="thumb">
                              {item.situation === 0 && (
                                <FaThumbsDown color="#E53935" />
                              )}
                              {item.situation === 1 && (
                                <FaThumbsUp color="#006229" />
                              )}
                              {item.situation === 2 && (
                                <FaTimes color="#E53935" />
                              )}
                            </th>
                            <th
                              className="client"
                              style={{ cursor: 'pointer' }}
                              data-tip={item.client.name}
                              data-for="client"
                              onClick={() => {
                                setProcess(item);
                                alterView();
                                setIndexProcessView(indexItem);
                              }}
                            >
                              {item.client.nickname || item.client.name}
                            </th>
                            {item.documents &&
                              item.documents.map(document => (
                                <DocumentTd
                                  key={document.id}
                                  situation={document.situation}
                                  data-tip={
                                    document.document
                                      ? `${document.document} <br/> Venc.: ${document.formatted_expiration}`
                                      : ''
                                  }
                                  data-for="mark"
                                  onClick={() =>
                                    handleUpdateDocumentModal(
                                      item.user_id,
                                      document,
                                      item.client
                                    )
                                  }
                                >
                                  {document.situation === 0 && (
                                    <FaClock size={18} color="#006229" />
                                  )}
                                  {document.situation === 1 && (
                                    <FaClock size={18} color="#f4c306" />
                                  )}
                                  {document.situation === 2 && (
                                    <FaClock size={18} color="#E53935" />
                                  )}
                                  {document.situation === 3 && (
                                    <FaMinus size={18} color="#e1e1e1" />
                                  )}
                                  {document.situation === 4 && (
                                    <FaRegCircle size={18} color="#9dd3fe" />
                                  )}
                                </DocumentTd>
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </TableContainer>
                </Content>
              </>
            ) : (
              <Content className="content">
                <DetailsContainer>
                  {process && (
                    <>
                      <h4>PROCESSO</h4>
                      <section>
                        <div className="title">
                          <label>Cliente</label>
                          <input
                            name="name"
                            value={process.client.name || ''}
                            readOnly
                          />
                        </div>

                        <div className="type">
                          <label>Modelo</label>
                          <input
                            name="typeModel"
                            value={processType.title}
                            readOnly
                          />
                        </div>

                        <div className="user">
                          <label>Responsável</label>
                          <input
                            name="name"
                            value={
                              usersOptions.find(
                                option => option.value === process.user_id
                              )?.label || ''
                            }
                            readOnly
                          />
                        </div>
                      </section>
                      <section className="sector">
                        <div className="checkbox">
                          <label>Trabalhista</label>
                          <input
                            name="labor"
                            checked={process.labor}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Tributário</label>
                          <input
                            name="tributary"
                            checked={process.tributary}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Contábil</label>
                          <input
                            name="accounting"
                            checked={process.accounting}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Financeiro</label>
                          <input
                            name="financial"
                            checked={process.financial}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Administração</label>
                          <input
                            name="administration"
                            checked={process.administration}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      </section>

                      <ReactTooltip
                        id="release"
                        place="bottom"
                        type="info"
                        backgroundColor="#01579B"
                        effect="solid"
                      />

                      <ReactTooltip
                        id="view_feedback"
                        place="bottom"
                        type="info"
                        backgroundColor="#337ab7"
                        effect="solid"
                        multiline
                      />

                      {process.documents.length > 0 && (
                        <div className="documents-title">
                          <h4 className="description">
                            DOCUMENTAÇÃO NECESSÁRIA
                          </h4>
                          <FaBan
                            color="#01579B"
                            data-tip="Desobrigar todos"
                            data-for="release"
                            onClick={confirmReleaseAllDocuments}
                          />
                        </div>
                      )}
                      {process.documents.map((item, index) => (
                        <section key={index}>
                          {item.hasScheduleFeedbacks ? (
                            <div
                              data-tip="Feedbacks"
                              data-for="view_feedback"
                              className="view-feedbacks"
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  handleViewDocumentFeedbacks(item.id)
                                }
                              >
                                <FaEye color="#00c853" size={15} />
                              </button>
                            </div>
                          ) : (
                            <div
                              data-tip="Sem feedbacks"
                              data-for="view_feedback"
                              className="view-feedbacks"
                            >
                              <button type="button">
                                <FaEye color="#44546a" size={15} />
                              </button>
                            </div>
                          )}

                          <div className="document">
                            <label>Documento</label>
                            <input
                              name="documentName"
                              value={item.document || ''}
                              readOnly
                            />
                          </div>

                          <div className="date">
                            <label>Vencimento</label>
                            <input
                              name="expiration"
                              value={item.formatted_expiration || ''}
                              readOnly
                            />
                          </div>
                          <div className="responsible">
                            <label>Responsável</label>
                            <input
                              name="responsible_id"
                              value={
                                item.responsible_id
                                  ? usersOptions.find(
                                      element =>
                                        element.value === item.responsible_id
                                    ).label
                                  : 'Nenhum'
                              }
                              readOnly
                            />
                          </div>
                          <div className="deadline">
                            <label>Notificar (x) dias antes</label>
                            <input
                              name="days"
                              value={item.days_to_notificate || ''}
                              readOnly
                            />
                          </div>
                          {item.file && (
                            <div className="file">
                              <aside>
                                <button
                                  type="button"
                                  onClick={() => openFile(item.file_url)}
                                  title="Visualizar arquivo"
                                >
                                  <FaEye size={16} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    downloadFile(item.file_url, item.file)
                                  }
                                  title="Baixar arquivo"
                                >
                                  <FaDownload size={16} />
                                </button>
                              </aside>
                              <div>
                                <label>Arquivo</label>
                                <input
                                  name="file"
                                  value={item.file || ''}
                                  readOnly
                                />
                              </div>
                            </div>
                          )}

                          <div className="checkbox">
                            <label>Desobrigado</label>
                            <input
                              name="required"
                              checked={item.situation === 3}
                              type="checkbox"
                              disabled
                            />
                          </div>
                        </section>
                      ))}
                    </>
                  )}

                  <Feedback ref={feedbackRef} onSubmit={handleFeedback}>
                    <h4>FEEDBACK</h4>
                    <SendFeedback>
                      <TextArea
                        id="feedback_message"
                        name="feedback_message"
                        className="feedback_message"
                        label="Mensagem (CTRL + ENTER para enviar)"
                        type="text"
                      />

                      <UploadFile>
                        {uploadFile.name && (
                          <FileName>
                            <label htmlFor="filename">Arquivo</label>
                            <input
                              id="fileName"
                              value={uploadFile.name}
                              readOnly
                              type="text"
                            />
                          </FileName>
                        )}
                        <File>
                          <label htmlFor="file">
                            <FaPaperclip size={14} color="#FCFCFC" />
                          </label>
                          <FileInput
                            id="file"
                            name="file"
                            onChange={e => {
                              setUploadFile(e.target.files[0]);
                            }}
                          />
                        </File>
                      </UploadFile>

                      <button
                        type="button"
                        onClick={() => feedbackRef.current.submitForm()}
                      >
                        <FaLocationArrow size={14} />
                      </button>
                    </SendFeedback>
                    {process.feedbacks.length > 0 &&
                      process.feedbacks.map(feedback => (
                        <FeedbackItem key={feedback.id}>
                          <div className="delete">
                            <button
                              type="button"
                              onClick={() =>
                                confirmRemoveFeedback(
                                  feedback.id,
                                  feedback.process_id,
                                  feedback.user.id,
                                  feedback.created_at
                                )
                              }
                            >
                              <FaTrash size={14} />
                            </button>
                          </div>
                          <div className="date">
                            <label>Data/Hora</label>
                            <input
                              name="feedback_date_formatted"
                              value={feedback.feedback_date}
                              readOnly
                            />
                          </div>
                          <div className="user">
                            <label>Usuário</label>
                            <input
                              name="feedback_user"
                              value={feedback.user.short_name}
                              readOnly
                            />
                          </div>
                          <div className="content">
                            <label>Mensagem</label>
                            <TextareaAutosize
                              name="content"
                              value={feedback.content}
                              readOnly
                              maxRows={4}
                            />
                          </div>
                          {feedback.type === 1 && (
                            <div className="file">
                              <aside>
                                <button
                                  type="button"
                                  onClick={() => openFile(feedback.link)}
                                  title="Visualizar arquivo"
                                >
                                  <FaEye size={16} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    downloadFile(
                                      feedback.link,
                                      feedback.file_name
                                    )
                                  }
                                  title="Baixar arquivo"
                                >
                                  <FaDownload size={16} />
                                </button>
                              </aside>
                              <div>
                                <label>Arquivo</label>
                                <input
                                  type="text"
                                  value={feedback.file_name}
                                  readOnly
                                />
                              </div>
                            </div>
                          )}
                        </FeedbackItem>
                      ))}
                  </Feedback>
                </DetailsContainer>
              </Content>
            )}
          </>
        )}
        {showTable && (
          <>
            <Pagination
              loading={processesLoading ? 1 : 0}
              currentPage={currentPage}
              pages={totalPages}
              totalDocs={totalProcesses}
              handlePage={handlePage}
            />
            <Subtitles>
              <aside>
                <div>
                  <FaRegCircle size={12} color="#9dd3fe" />
                  <span>Não atribuído</span>
                </div>
                <div>
                  <FaMinus size={12} color="#e1e1e1" />
                  <span>Desobrigado</span>
                </div>
                <div>
                  <FaClock size={12} color="#006229" />
                  <span>Dentro do prazo</span>
                </div>
                <div>
                  <FaClock size={12} color="#f4c306" />
                  <span>A vencer</span>
                </div>
                <div>
                  <FaClock size={12} color="#E53935" />
                  <span>Vencido</span>
                </div>
              </aside>
            </Subtitles>
          </>
        )}
      </Container>

      {(loading ||
        deleteFeedbackLoading ||
        feedbackLoading ||
        reportLoading) && <Loading />}

      {updateDocumentModalIsOpen && (
        <UpdateDocumentModal
          setIsOpen={() => setUpdateDocumentModalIsOpen(false)}
          loadProcesses={loadProcesses}
          document={documentForModal}
          companyUsers={companyUsers}
          user_id={user.id}
          permissionRequired={companyUser.level < 6}
        />
      )}

      {feedbacksModalIsOpen && (
        <FeedbacksModal
          data={feedbacksForModal.current}
          isOpen={feedbacksModalIsOpen}
          setIsOpen={() => setFeedbacksModalIsOpen(false)}
          type="documents"
        />
      )}

      {documentsModalIsOpen && (
        <DocumentsModal
          isOpen={documentsModalIsOpen}
          setIsOpen={() => setDocumentsModalIsOpen(false)}
          filter={filterForDocumentsModal.current}
        />
      )}

      <div ref={reportRef}>
        <Report clients={report} />
      </div>
    </>
  );
};

export default List;

List.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      model_id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
